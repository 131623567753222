
import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'gatsby'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Layout from '../components/Layout'
import Paragraph from '../components/Paragraph'
import SecondaryTitle from '../components/SecondaryTitle'
import Button from '../components/Button'
import NotFoundContainer from '../components/NotFoundContainer'
import Seo from '../components/SEO'

const NotFound = ({ location }) => {
  return (
    <React.Fragment>
      <Seo
        title='404: Not Found'
        description='Page not found or has been moved'
        pathname={'https://path.berlin/' + location.pathname}
      />
      <Layout>
        <Header logoFill='#fff' bgFill='#0085ff' />
      </Layout>
      <Layout>
        <NotFoundContainer>
          <SecondaryTitle>
            Page not found
          </SecondaryTitle>
          <Paragraph>
            The page you are looking for doesn’t exist, or has been moved
          </Paragraph>
          <div>
            <Button as={Link} to='/'>
              Return home
            </Button>
          </div>
        </NotFoundContainer>
      </Layout>
      <Footer />
    </React.Fragment>
  )
}

NotFound.propTypes = {
  location: PropTypes.object
}

export default NotFound
